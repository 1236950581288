import { API } from "aws-amplify";
import { withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { tomorrow } from "react-syntax-highlighter/dist/esm/styles/prism";
import { useState } from "react";
import "./App.css";
import { Button, Toast } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { getMessagingToken, onMessageListener } from "./firebase";

function App({ signOut, user }) {
  console.log("tokens grab accessToken for postman", {
    accessToken: user.signInUserSession.accessToken.jwtToken,
    jwtToken: user.signInUserSession.accessToken.jwtToken,
    jwtTokenPayload: user.signInUserSession.idToken.payload,
  });

  const [response, setResponse] = useState(null);

  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });
  const [isTokenFound, setTokenFound] = useState(false);

  onMessageListener()
    .then((payload) => {
      setShow(true);
      setNotification({ title: payload.notification.title, body: payload.notification.body });
      console.log(payload);
    })
    .catch((err) => console.log("failed: ", err));

  function askForPermissionToReceiveNotifications() {
    console.log("askForPermissionToReceiveNotifications");
    getMessagingToken(setTokenFound, (token) => {
      console.log("storing token", token);
      onClickPatch({
        settings: { push: { token } },
      });
    });
  }

  // askForPermissionToReceiveNotifications();

  function onClickGetMember() {
    API.get("Api", "/members", {
      Accept: "application/json, text/plain, */*",
    })
      .then((response) => {
        setResponse(response);
      })
      .catch((error) => console.log(error.response));
  }

  function onClickPut() {
    API.put("Api", "/members", {
      Accept: "application/json, text/plain, */*",
      body: {
        mobileNumber: user.attributes.phone_number,
      },
    })
      .then((response) => {
        setResponse(response);
      })
      .catch((error) => console.log(error.response));
  }

  function onClickPatch(body) {
    API.patch("Api", "/members", {
      Accept: "application/json, text/plain, */*",
      body,
    })
      .then((response) => {
        setResponse(response);
      })
      .catch((error) => console.log(error.response));
  }

  function onClickInvites() {
    API.post("Api", "/employers/invite", {
      Accept: "application/json, text/plain, */*",
      body: {
        employer: {
          employerName: "Restaurant Name",
          contactFirstName: "employer first name",
          contactLastName: "employer last name",
          contactEmail: "testing@dev.vlrmnetworks.com.au",
        },
        employee: {
          firstName: "Lily",
          lastName: "Andrews",
          jobTitle: "Waiter",
        },
      },
    })
      .then((response) => {
        setResponse(response);
      })
      .catch((error) => console.log(error.response));
  }

  function onClickShowToken() {
    setResponse({
      accessToken: user.signInUserSession.accessToken.jwtToken,
      jwtToken: user.signInUserSession.accessToken.jwtToken,
      jwtTokenPayload: user.signInUserSession.idToken.payload,
    });
  }

  function onClickGetLocations() {
    API.get("Api", "/locations", {
      Accept: "application/json, text/plain, */*",
    })
      .then((response) => {
        setResponse(response);
      })
      .catch((error) => console.log(error.response));
  }

  function onClickGetNotifications() {
    API.get("Api", "/notifications", {
      Accept: "application/json, text/plain, */*",
    })
      .then((response) => {
        setResponse(response);
      })
      .catch((error) => console.log(error.response));
  }

  function onClickPatchNotification() {
    API.patch("Api", "/notifications", {
      Accept: "application/json, text/plain, */*",
      body: {
        notifications: [
          {
            notificationId: "01HA624R72MRYN02DRCZHQ5KAE",
            markAsRead: true,
          },
          {
            notificationId: "01HA660V3MQXX38X4N90548RJ8",
            markAsRead: true,
          },
        ],
      },
    })
      .then((response) => {
        setResponse(response);
      })
      .catch((error) => console.log(error.response));
  }

  return (
    <div className="App">
      <header className="App-header">
        <Toast
          onClose={() => setShow(false)}
          show={show}
          delay={3000}
          autohide
          animation
          style={{
            position: "absolute",
            top: 20,
            right: 20,
            minWidth: 200,
          }}
        >
          <Toast.Header>
            <strong className="mr-auto">{notification.title}</strong>
            <small>just now</small>
          </Toast.Header>
          <Toast.Body>{notification.body}</Toast.Body>
        </Toast>

        <h1>Hello {user.username}</h1>
        <p>
          {isTokenFound && "Notification permission enabled 👍🏻"}
          {!isTokenFound && "Need notification permission ❗️"}
        </p>
        <ul className="list-group">
          <li className="list-group-item">
            <Button onClick={onClickShowToken}>Show JWT</Button>
          </li>
          <li className="list-group-item">
            <Button onClick={onClickPut}>PUT /members</Button>
          </li>
          <li className="list-group-item">
            <Button
              onClick={() =>
                onClickPatch({
                  dateOfBirth: "1999-12-25",
                })
              }
            >
              PATCH /members - dateOfBirth
            </Button>
          </li>
          <li className="list-group-item">
            <Button
              onClick={() =>
                onClickPatch({
                  settings: { push: { transactions: true } },
                })
              }
            >
              PATCH /members - turn on transactions notification
            </Button>
          </li>
          <li className="list-group-item">
            {" "}
            <Button
              onClick={() =>
                onClickPatch({
                  settings: { push: { transactions: false } },
                })
              }
            >
              PATCH /members - turn off transactions notification
            </Button>
          </li>
          <li className="list-group-item">
            <Button
              onClick={() =>
                onClickPatch({
                  settings: { push: { tips: true } },
                })
              }
            >
              PATCH /members - turn on tips notification
            </Button>
          </li>
          <li className="list-group-item">
            <Button
              onClick={() =>
                onClickPatch({
                  settings: { push: { tips: false } },
                })
              }
            >
              PATCH /members - turn off tips notification
            </Button>
          </li>
          <li className="list-group-item">
            <Button onClick={onClickGetMember}>GET /members</Button>
          </li>
          <li className="list-group-item">
            <Button onClick={onClickInvites}>POST /employers/invite</Button>
          </li>
          <li className="list-group-item">
            {" "}
            <Button onClick={onClickGetLocations}>GET /locations</Button>
          </li>
          <li className="list-group-item">
            <Button onClick={onClickGetNotifications}>GET /notifications</Button>
          </li>
          <li className="list-group-item">
            <Button onClick={onClickPatchNotification}>PATCH /notifications</Button>
          </li>
          <li className="list-group-item">
            <Button onClick={() => setShow(true)}>Show Toast</Button>
          </li>
          <li className="list-group-item">
            {" "}
            <Button onClick={() => askForPermissionToReceiveNotifications()}>Ask for Push Permission</Button>
          </li>
          <li className="list-group-item">
            <Button onClick={signOut}>Sign out</Button>
          </li>
          <li className="list-group-item">
            {response !== null && (
              <>
                <h2>Response:</h2>
                <SyntaxHighlighter language="json" style={tomorrow}>
                  {JSON.stringify(response, null, 2)}
                </SyntaxHighlighter>
              </>
            )}
          </li>
        </ul>
      </header>
    </div>
  );
}

export default withAuthenticator(App);
